<template>
    <div class="set-up-container">
        <div class="config-panel">
            <div class="title-heard " style="padding-left: 20px;position: relative">
                水表设置
            </div>
            <hr>
            <div class="wrap" style="padding-bottom: 0">
                <div class="form-item">
                    <div class="form-item-name">关联水表第三方平台</div>
                    <CSSelect
                            style="
                  width: 380px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                    >
                        <select
                                :style="{color: setInformation.waterId === '' ? '#999' : '#444',}"
                                v-model="setInformation.waterId"
                        >
                            <option value="">请选择</option>
                            <option v-for="item in setInformation.water" :key="item.id" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <div class="form-item">
                    <div class="form-item-name">基础水价（元/吨)</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="setWater.waterPrice"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">附加费用（元/吨)</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="setWater.waterOtherPrice"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">水价总计（元/吨)</div>
                    <span>{{
              parseFloat(setWater.waterOtherPrice) +
              parseFloat(setWater.waterPrice)
                  ? (
                      parseFloat(setWater.waterOtherPrice) +
                      parseFloat(setWater.waterPrice)
                  ).toFixed(2)
                  : "-"
            }}</span>
                </div>

                <div class="form-item">
                    <div class="form-item-name">
                        消耗月报发送日期
                    </div>
                    <div style="line-height: 30px; height: 30px;">次月</div>
                    <CSSelect style="margin: 0px 30px 0px 10px; min-width: 120px">
                        <select v-model="setWater.sendDateWater">
                            <option
                                    :value="item.id"
                                    v-for="item in dataList"
                                    :key="item.name"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <div class="form-item-name item-show">

                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-menua-zu92"></use>
                    </svg
                    >
                    <span
                            style="
                font-weight: 400;
                line-height: 20px;
                color: #999999;
                margin-left: 5px;
              "
                    >设置日期后，次月指定日期，租客将会收到上月水/电费累计消耗金额的账单。</span
                    >
                </div>
                <div class="wrap" style="padding-bottom: 0">
                    <div style="margin: 15px 0px 0px">
                        <button class="btn btn-primary" @click="sumbitBtn('water')" :disabled = 'waterDisable'
                                style="margin-left: 30px; height: 30px;width: 50px;padding: 0">保存
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="config-panel" style="margin-top: 20px;">
            <div class="title-heard " style="padding-left: 20px;position: relative">
                电表设置
            </div>
            <hr>
            <div class="wrap" style="padding-bottom: 0">
                <div class="form-item">
                    <div class="form-item-name">关联电表第三方设置</div>
                    <div>
                        <CSSelect
                                style="
                  width: 300px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                        >
                            <select
                                    :style="{
                    color: setInformation.electricId === '' ? '#999' : '#444',
                  }"
                                    v-model="setInformation.electricId"
                            >
                                <option value="">请选择</option>
                                <option v-for="item in setInformation.electric" :key="item.id" :value="item.id">{{
                                    item.name
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">基础电价（元/度)</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="setUpMoney.electricityPrice"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">附加费用（元/度）</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="setUpMoney.electricityOtherPrice"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name">电价总计（元/度)</div>
                    <span>{{
              parseFloat(setUpMoney.electricityPrice) +
              parseFloat(setUpMoney.electricityOtherPrice)
                  ? (
                      parseFloat(setUpMoney.electricityPrice) +
                      parseFloat(setUpMoney.electricityOtherPrice)
                  ).toFixed(2)
                  : "-"
            }}</span>
                </div>
                <div class="form-item">
                    <div class="form-item-name">
                        消耗月报发送日期
                    </div>
                    <div style="line-height: 30px; height: 30px;">次月</div>
                    <CSSelect style="margin: 0px 30px 0px 10px; min-width: 120px">
                        <select v-model="setUpMoney.sendDate">
                            <option
                                    :value="item.id"
                                    v-for="item in dataList"
                                    :key="item.name"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </CSSelect>
                </div>
                <div class="form-item-name item-show">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-menua-zu92"></use>
                    </svg
                    >
                    <span
                            style="
                font-weight: 400;
                line-height: 20px;
                color: #999999;
                margin-left: 5px;
              "
                    >设置日期后，次月指定日期，租客将会收到上月水/电费累计消耗金额的账单。</span
                    >
                </div>
                <div class="wrap" style="padding-bottom: 0">
                    <div style="margin: 15px 0px 0px">
                        <button class="btn btn-primary" @click="sumbitBtn('elent')"
                                style="margin-left: 30px;height: 30px;width: 50px;padding: 0" :disabled = 'eleDisable'>保存
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="config-panel" style="margin-top: 20px;">
            <div class="title-heard " style="padding-left: 20px;position: relative">
                水电公摊设置
            </div>
            <hr>
            <div class="wrap" style="padding-bottom: 0">
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">剩余公摊水电费承担公司</div>
                    <div>
                        <CSSelect
                                style="
                  width: 300px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                        >
                            <select
                                    :style="{
                    color: publicShare.equallySharedCompany === '' ? '#999' : '#444',
                  }"
                                    v-model="publicShare.equallySharedCompany"
                            >
                                <option value="">请选择</option>
                                <option v-for="item in tenantAllList" :key="item.id" :value="item.id">{{
                                    item.companyName
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">水费公摊总面积（㎡）</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="publicShare.equallySharedWater"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">电费公摊总面积（㎡）</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="publicShare.equallySharedElectricity"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">公摊水价（元/吨）</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="publicShare.equallySharedWaterPrice"
                        />
                    </div>
                </div>
                <div class="form-item" style="margin-bottom: 15px">
                    <div class="form-item-name form-item-name-b">公摊电价（元/度）</div>
                    <div>
                        <input
                                type="number"
                                min="0"
                                placeholder="保留小数点后2位"
                                v-model="publicShare.equallySharedElectricityPrice"
                        />
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">园区总水表</div>
                    <div>
                        <CSSelect
                                style="
                  width: 300px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                        >
                            <select
                                    :style="{
                    color: publicShare.regionWaterCode === '' ? '#999' : '#444',
                  }"
                                    v-model="publicShare.regionWaterCode"
                            >
                                <option value="">请选择</option>
                                <option v-for="item in publicDataWarte" :key="item.id" :value="item.id">{{
                                    item.name
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">园区总电表</div>
                    <div>
                        <CSSelect
                                style="
                  width: 300px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                        >
                            <select
                                    :style="{
                    color:publicShare.regionElectricityCode=== '' ? '#999' : '#444',
                  }"
                                    v-model="publicShare.regionElectricityCode"
                            >
                                <option value="">请选择</option>
                                <option v-for="item in publicDataEles" :key="item.id" :value="item.id">{{
                                    item.name
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-name form-item-name-b">私区表组</div>
                    <div>
                        <CSSelect
                                style="
                  width: 300px;
                  height: 30px;
                  border: 1px solid #979797;
                  opacity: 1;
                  border-radius: 4px;
                "
                        >
                            <select
                                    :style="{
                    color: publicShare.privateGroupId === '' ? '#999' : '#444',
                  }"
                                    v-model="publicShare.privateGroupId"
                            >
                                <option value="">请选择</option>
                                <option v-for="item in MeterGroupList" :key="item.id" :value="item.id">{{
                                    item.name
                                    }}
                                </option>
                            </select>
                        </CSSelect>
                    </div>
                </div>
                <div class="wrap" style="padding-bottom: 0">
                    <div style="margin: 5px 0px 0px">
                        <button class="btn btn-primary" @click="sumbitBtn('share')"
                                style="margin-left: 30px;height: 30px;width: 50px;padding: 0" :disabled = 'billDisable'>保存
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CSTabBar from "@/components/common/CSTabBar";
    import CSSelect from "@/components/common/CSSelect";
    import {
        hydropowerGetInfoUrl,
        queryInstrumentUrl,
        setHydropowerUrl,
        getHydropowerPartyInformationUrl,
        preservationHydropowerUrl,
        getHydropowerPartyInfoUrl,
        hydropowerGetTenantUrl,
        addOrModifySharedConfigUrl,
        querySharedConfigUrl,
        queryMeterGroupUrl
    } from "@/requestUrl";
    import dayjs from "dayjs";

    export default {
        created() {
            this.getDateDay();
            this.getSelectInfo(1);
            this.getSelectInfo(2);
            //获取企业
            this.getTenantAllList();
        },
        mounted() {
            this.getHydropowerInfo();
            this.getInfo(1);
            this.getInfo(2);
            //查询水电公摊设置
            this.getQuerySharedConfig();
            //查询公区水表
            this.queryB(1);
            //查询公区电表
            this.queryB(2);
            //查询私区表组
            this.getMeterGroupList();
        },
        data() {
            return {
                waterDisable:false,
                eleDisable:false,
                billDisable:false,
                meterGroup:'',
                MeterGroupList:[],
                publicDateWa:'',
                publicDateEles:'',
                publicDataWarte:[],
                publicDataEles:[],
                date: "",
                dataList: [],
                tenantAllList: [],//所有企业
                checkedTaBar: 1,
                //水电公摊设置
                publicShare: {
                    equallySharedCompany: '',//承担公司
                    equallySharedElectricity: '',//电费公摊总面积
                    equallySharedWater: '', //水费公摊总面积
                    equallySharedWaterPrice: "",//公摊水价
                    equallySharedElectricityPrice: '',//公摊电价
                    regionWaterCode: '',// 园区总水表
                    regionElectricityCode: '',// 园区总电表
                    privateGroupId: '', //私区表组
                    equallySharedCompanyName: '' // 公司名称
                },
                tabBar: {
                    1: "设置"
                },
                setUpMoney: {
                    electricityPrice: null, //电基本价格
                    electricityOtherPrice: null, //电其他价格
                    sendDate: null, //发送账单日期
                },
                setWater: {
                    waterPrice: null, //水基本价格
                    waterOtherPrice: null, //水其他价格
                    sendDateWater: null//发送账单日期
                },
                //关联第三方信息
                setInformation: {
                    water: [], //水表
                    electric: [], //电表
                    waterValue: "",
                    electricValue: "",
                    waterId: "",
                    electricId: "",
                },
                InfoList: [],
            };
        },
        methods: {
            //查询公区仪表
            queryB(type){
                let params = {
                    meterInfo: "",
                    category: 2,
                    type,//1水表2dianb
                    style: "",
                    pageNo:1,
                    pageSize:9999,
                };
                this.$fly.post(queryInstrumentUrl, params).then((res) => {
                    console.log(res.data);
                    if(type == 1){
                        this.publicDataWarte = res.data.datas;//公区随表
                    }else{
                        this.publicDataEles = res.data.datas;//公区电表
                    }

                });
            },
            sumblitBtn() {
                this.sumbitInfoWater(this.setInformation.waterId, this.setInformation.electricId);
            },
            //提交关联的第三方水表信息
            sumbitInfoWater(waterId, electricityId) {
                this.$fly
                    .post(preservationHydropowerUrl, {
                        waterId, //第三方信息id
                        electricityId,
                        regionCode: this.$vc.getCurrentRegion().code, // 区域编码
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("保存成功");
                    });
            },
            //查询当前第三方关联信息
            getSelectInfo(number) {
                this.$fly.get(getHydropowerPartyInfoUrl, {
                    regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    type: number, //1水 2电
                }).then((res) => {
                    if (res.code != 0) {
                        return
                    }
                    number == 1 ? this.setInformation.waterId = res.data.id : this.setInformation.electricId = res.data.id;
                });
            },
            //获取关联的水表第三方平台信息
            getInfo(number) {
                this.$fly
                    .get(getHydropowerPartyInformationUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, // 区域编码
                        type: number, //1水 2电
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        if (number == 1) {
                            this.setInformation.water = res.data;
                        } else if (number == 2) {
                            this.setInformation.electric = res.data;
                        }
                    });
            },
            //查询私区表组
            getMeterGroupList(){
                this.$fly.post(queryMeterGroupUrl, {
                    search: "", //搜索框内容
                    pageSize: 9999, //页面大小
                    pageNo: 1, //页码
                    regionCode: this.$vc.getCurrentRegion().code, //区域编码
                })
                    .then(res => {
                        if (res.code != 0) {
                            return;
                        }

                        this.MeterGroupList = res.data.datas
                    })
            },
            //设置提交第三方信息
            setHydropowerInfo(name) {
                let arr;
                if (name == 'water') {
                    this.waterDisable = true;
                    this.setWater.sendDate = this.setWater.sendDateWater;
                    delete this.setWater.sendDateWater
                    arr = {
                        ...this.setWater,
                        type:1
                    }
                    this.$set(this.setWater,'sendDateWater', this.setWater.sendDate);
                } else if (name == 'share') {
                    this.billDisable = true;
                  delete this.publicShare.equallySharedCompanyName;
                    this.$fly.post(addOrModifySharedConfigUrl, {
                            ...this.publicShare,
                            regionCode: this.$vc.getCurrentRegion().code,
                        }
                    ).then(res => {
                        this.billDisable = false;
                        if(res.code != 0){
                            return
                        }
                        this.$vc.toast("修改成功");
                    })
                    return
                } else {
                    this.eleDisable =true;
                    arr = {
                        ...this.setUpMoney,
                        type:2
                    }
                }

                this.$fly
                    .post(setHydropowerUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域编码, //区域编码
                        ...arr,
                    })
                    .then((res) => {
                        if(name == 'water'){
                            this.waterDisable = false;
                        }else{
                            this.eleDisable =false;
                        }

                        if (res.code != 0) {
                            return;
                        }
                        this.$vc.toast("修改成功");
                    });
            },
            //信息提交
            sumbitBtn(name) {
                this.setHydropowerInfo(name);
            },
            //获取所有企业列表
            getTenantAllList() {
                this.$fly
                    .post(hydropowerGetTenantUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                        search: this.queryInput,
                    })
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.tenantAllList = res.data;
                    });
            },
            //查询水电费公摊设置
            getQuerySharedConfig(){
                this.$fly.get(querySharedConfigUrl,{
                    regionCode:this.$vc.getCurrentRegion().code
                }).then(res => {
                    if(res.code != 0){
                        return
                    }
                    console.log('查询水电公摊');
                    console.log(res);
                    this.publicShare = {
                        ...res.data
                    }
                })
            },
            //获取水电表基础信息
            getHydropowerInfo() {
                this.$fly
                    .get(hydropowerGetInfoUrl, {
                        regionCode: this.$vc.getCurrentRegion().code, //区域编码
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        console.log('基础信息');
                        console.log(res.data);
                        this.setUpMoney = {
                            electricityId: res.data.electricityId,
                            electricityOtherPrice: res.data.electricityOtherPrice,
                            electricityPrice: res.data.electricityPrice,
                            sendDate: res.data.electricitySendDate
                        };
                        this.setWater = {
                            waterId: res.data.waterId,
                            waterOtherPrice: res.data.waterOtherPrice,
                            waterPrice: res.data.waterPrice,
                            sendDateWater: res.data.waterSendDate
                        }

                    });
            },
            //获取日期天数
            getDateDay() {
                for (var i = 0; i < 25; i++) {
                    var j = i + 1;
                    this.dataList.push({name: j + "号", id: j});
                }
            },
            changeTabBar(index) {
                this.checkedTaBar = +index;
            },
        },
        components: {
            CSTabBar,
            CSSelect,
        },
    };
</script>

<style scoped lang="stylus">

    .form-item {
        display: flex;
        margin: 0 0 15px 0;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #000000;
        opacity: 1;
        align-items: center;
    }

    .wrap .form-item:nth-child(5) {
        margin-bottom 5px
    }

    .form-item input {
        height: 30px;
        border: 1px solid #979797;
        opacity: 1;
        border-radius: 3px;
        padding-left: 10px;
    }

    .form-item .form-item-name {
        width: 158px;
        display: inline-block;
        text-align: right;
        margin-right: 20px;

    }


    .form-item-name .icon {
        margin-left 15px;
    }

    .wrap {
        padding: 15px 0;
        border-bottom: 0 solid #f0f0f0;
    }

    .config-panel {
        background: #fff;
        padding: 20px 0px;
        min-height: 200px;
        color: #444;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);

        .config-item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            &-label {
                width: 70px;
                font-size: 14px;
                margin-right: 45px;
                text-align: right;
            }

            input {
                height: 30px;
                padding: 0 5px;
                width: 88px;
                border-radius: 3px;
                border: 1px solid #979797;
                margin-right: 4px;
            }

            select {
                option {
                    color: #444;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }
        }
    }

    .title-heard {
        height: 28px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 28px;
        color: #000000;
        opacity: 1;
        position relative
        margin-left 22px
    }

    .title-heard::before {
        position absolute
        content ""
        width 6px
        height 18px
        background rgba(0, 182, 148, 1)
        opacity 1
        left 8px
        top calc(50% - 9px)
    }

    .item-show {
        margin-left 164px
    }

    .form-item-name-b {
        margin-left 29px
    }
</style>
